
<template>
    <div>
        <div
            v-if="statusLoading"
            class="layout justify-center py-5">
            <loader label="Checking onboarding status..." />
        </div>
        <template v-else>
            <div v-if="onboardingValid">
                <h2>Onboarding Complete!</h2>

                <p
                    v-if="onboardingValid"
                    class="mb-4">
                    You’re to the finish line 🏆 and can complete the onboarding
                    for {{ onboardingProductNames }}. Just click the button below
                    you're good to go!
                </p>
            </div>
            <div v-else>
                <h2>Onboarding Not Yet Completed!</h2>
                <p class="mb-4">
                    You're not quite ready to complete onboarding for this product.
                    Please review  the missing dependencies below and revisit the
                    outstanding steps as there's likely additional actions you still
                    need to take then we can get rolling 🚗!
                </p>
                <incomplete-features class="mb-4" />
            </div>
            <div class="layout align-center">
                <back-button @click="$store.dispatch('goToPreviousStep')" />
                <div class="ml-auto" />
                <a
                    v-if="!onboardingValid"
                    href="#"
                    class="mr-3"
                    @click.prevent="$store.dispatch('exitOnboarding')">
                    Save &amp; Exit
                </a>
                <styled-button
                    :disabled="!onboardingValid"
                    :loading="loadingCompleteOnboarding"
                    @click="onCompleteOnboarding">
                    COMPLETE ONBOARDING
                </styled-button>
            </div>
        </template>
    </div>
</template>

<script>
import IncompleteFeatures from '../../components/IncompleteFeatures';
import Loader from '@/components/globals/Loader';
import BackButton from '@/components/globals/BackButton';
import StyledButton from '@/components/globals/StyledButton';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'OnboardingPlayReview',
    components: {
        Loader,
        IncompleteFeatures,
        BackButton,
        StyledButton,
    },
    data() {
        return {
            loadingCompleteOnboarding: false,
            error: null
        };
    },
    computed: {
        ...mapState({
            onboardingProducts: (state) => state.onboarding.products,
            statusLoading: (state) => state.onboarding.statusLoading,
        }),
        ...mapGetters([
            'onboardingValid',
        ]),
        onboardingProductNames() {
            return this.onboardingProducts?.map(product => product?.display_name)?.join(' & ');
        }
    },
    activated() {
        // Force a status update on all features to ensure we have a fresh state
        // also ensure that we're re-setting the existing features in case a
        // state has changed
        this.$store.dispatch('forceUpdateOnboardingStatus', {
            updateExisting: true
        });
    },
    methods: {
        onCompleteOnboarding() {
            this.loadingCompleteOnboarding = true;
            const currentChannel = {
                name: this.onboardingProducts[0].name,
                id: this.onboardingProducts[0].platform_id
            };
            this.$store.dispatch('completeOnboarding', currentChannel);
        }
    },
};
</script>


